import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "pagination"
    }}>{`Pagination`}</h2>
    <ComponentCode name="Pagination" component="pagination" variation="pagination" hasReactVersion="pagination--pagination" hasVueVersion="pagination--default" hasAngularVersion="?path=/story/pagination--default" codepen="KYzgVB" mdxType="ComponentCode" />
    <h2 {...{
      "id": "disabled-pagination-buttons"
    }}>{`Disabled pagination buttons`}</h2>
    <ComponentCode name="Pagination nav" component="pagination" variation="pagination--disabled-pagination-buttons" hasReactVersion="pagination--pagination" codepen="LvNRZV" mdxType="ComponentCode" />
    <h2 {...{
      "id": "pagination-nav"
    }}>{`Pagination nav`}</h2>
    <ComponentCode name="Pagination nav" component="pagination-nav" variation="pagination-nav" mdxType="ComponentCode" />
    <h2 {...{
      "id": "pagination-nav-with-select"
    }}>{`Pagination nav with select`}</h2>
    <ComponentCode name="Pagination nav" component="pagination-nav" variation="pagination-nav--default--with-select" mdxType="ComponentCode" />
    <h2 {...{
      "id": "pagination-nav-as-anchor-tags"
    }}>{`Pagination nav as anchor tags`}</h2>
    <ComponentCode name="Pagination nav" component="pagination-nav" variation="pagination-nav--default--as-anchor" mdxType="ComponentCode" />
    <h2 {...{
      "id": "pagination-documentation"
    }}>{`Pagination Documentation`}</h2>
    <ComponentDocs component="pagination" mdxType="ComponentDocs" />
    <h2 {...{
      "id": "pagination-nav-documentation"
    }}>{`Pagination nav documentation`}</h2>
    <ComponentDocs component="pagination-nav" mdxType="ComponentDocs" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      